.color-nav {
  position: relative;
  display: inline-flex;
  padding: 56px;
  flex-direction: column;
  align-items: flex-start;
  gap: 48px;
  margin-top: 56px;
  background: var(--3, #ffffff);
  outline: solid 1px rgb(219, 219, 219);
}

.footer .navbar-brand:hover,
.footer .nav-link:hover {
  color: #000000;
  transition: 0.3s ease;
  text-decoration: underline;
  text-decoration-color: #9ce2d3;
  text-decoration-thickness: 3px;
}

.icon {
  border-radius: 19.286px;
  background: linear-gradient(
      0deg,
      rgba(156, 226, 211, 0.6) 0%,
      rgba(156, 226, 211, 0.6) 100%
    ),
    #fff;
  display: flex;
  width: 36px;
  height: 36px;
  padding: 10.286px;
  justify-content: center;
  align-items: center;
  gap: 6.429px;
}

.pages {
  width: 70%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
}

.footerNav {
  width: 100%;
  display: flex;
  padding: 0px 100px;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}

.copyright {
  color: var(--G1, #959598);
  font-family: Mulish;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  display: flex;
  width: 1728px;
  padding: 0px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.footer {
  overflow: hidden !important;
}

@media (max-width: 760px) {

  .fixed-bottom{
    background-color: #77AEA2 !important;
  }
  .copyright {
    padding: 0;
    text-align: left;
    color: white !important;
  }

  .navbar-text{
    color: white;
  }

  .footerNav {
    flex-direction: column;
    padding: 15px 0;
    width: auto;
    margin: 0;
    align-items: left;
    display: inline;
  }

  .pages {
    flex-direction: column;
    justify-content: left;
    margin-top: 15px;
    display: block;
    margin: 15 0 0 0;
    padding: 0;
    
  }

  .color-nav{
    gap: 10px !important;
  }

  .mr-auto {
    justify-content: left;
    margin: 0;
  }

  .navbar-nav {
    margin-top: 10px;
    justify-content: left;
    padding: 0;
  }

  .navbar .navbar-brand {
    display: flex;
    flex-direction: row;
    padding-top: 15px;
    justify-content: left;
    color: white;
    font-weight: bold;
  }
  .copyright {
    width: 100%;
    color: white;
  }

  

  .nav-link{
    padding-left: 0 !important;
    padding-right: 15px !important;
    width: 80px;
  }
  .footerwicc{
    display: none;
  }

  .color-nav {
    margin-top: 0px;
  }
}
