.hero {
  flex-shrink: 0;
  color: white;
}

.hero-text {
  position: absolute;
  top: 30%;
  margin-top: 5%;
  /* left: 0; */
  /* transform: translateY(-50%); */
  color: white;
  text-align: left;
  z-index: 2;
  /* max-width: 40%; */
  margin-right: 60%;
  padding-left: 8%;
}

.hero-image {
  filter: brightness(40%);
  object-fit: cover;
  overflow: hidden;
  width: 100vw;
  height:100vh;

}

.hero-text a {
  color: white;
  text-decoration: underline;
}

.hero-text a:hover {
  box-shadow: 0px 1px 1px 1px rgb(68, 68, 68);
}

.downward-arrow {
  position: absolute;
  bottom: 40px;  /* Adjust position from the bottom */
  left: 50%;
  transform: translateX(-50%);
  width: 50px;  /* Adjust size */
  height: auto;
  opacity: 0.8;
  transition: opacity 0.3s ease;
  animation: bounce 1.5s infinite;
  cursor: pointer;
}

/* Smooth hover effect */
.downward-arrow:hover {
  opacity: 1;
}

/* Bouncing animation */
@keyframes bounce {
  0%, 100% {
    transform: translateX(-50%) translateY(0);
  }
  50% {
    transform: translateX(-50%) translateY(10px);
  }
}

.carousel-wrapper {
  position: relative;
  width: 100%;
  max-width: 100%;
  height: 150px;
  margin: 0 auto;
}

.benefits-section {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 10vw;
  align-items: center;
  gap: 160px;
  background: var(--3, #fff);
}

.benefits-header {
  color: #000;
  font-family: Mulish;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}

.benefits-text {
  align-self: stretch;
  color: #000;
  font-family: Mulish;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.hero-img{
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
.hero img {
  object-fit: cover; /* Ensures the image covers the entire area without distortion */
}

.hero hi{
  min-height: 400px;
}




@media screen and (max-width: 1100px) {
  .benefits-section {
    flex-direction: column;
    flex-direction: column-reverse;
    gap: 20px;
  }
}

/* Text Responsive smaller screen */
@media screen and (max-width: 768px) {
  .top-section {
    flex-direction: column;
    padding: 50px 50px 0px 50px;
  }

  .top-header,
  .benefits-header {
    font-size: 34px;
  }

  .top-text,
  .benefits-text {
    font-size: 15px;
  }
}

@media screen and (max-width: 480px) {
  .top-header,
  .benefits-header {
    font-size: 20px;
  }

  .top-text,
  .benefits-text {
    font-size: 13px;
  }
}
#sponsorsTable {
  border-style: hidden;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
}

th {
  font-weight: 900;
  font-size: 24px;
  padding-bottom: 20px !important;
}

tbody tr:nth-child(odd) {
  background-color: rgb(156, 226, 211, 0.6);
  border-style: hidden;
}

tbody tr td {
  padding: 5px 15px !important;
}

td {
  vertical-align: middle !important;
}

td:first-child {
  border-radius: 20px 0 0 20px;
}

td:last-child {
  border-radius: 0 20px 20px 0;
}

.center {
  text-align: center;
}

#sponsorsTable img {
  width: 60px;
  height: 40px;
  display: block;
  margin: auto;
}

.container-section {
  background-color: #fbfbfb;
  padding: 40px 10px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
}
.description {
  color: #212529;
  font-family: Mulish;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.container-header {
  color: #212529;
  font-family: Mulish;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  text-align: left;
}

.container-subheader {
  color: #212529;
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  text-align: left;
  margin-bottom: 0;
}

.container1 {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  padding: 20px;
  flex: 0 1 calc(25% - 20px);
  max-width: calc(25% - 10px);
  margin-left: 40px;
}

.container2 {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  padding: 20px;
  flex: 1 1 calc(40% - 20px);
  max-width: calc(40% - 10px);
  margin-right: 5px;
}

@media screen and (min-width: 571px) {
  .responsive-text {
    display: block;
  }
}

.container3 {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  padding: 20px;
  flex: 0 1 calc(25% - 20px);
  max-width: calc(25% - 10px);
  margin-right: 40px;
}

@media (max-width: 768px) {
  .container1,
  .container2,
  .container3 {
    float: none;
    display: block;
    flex: none;
    max-width: 100%;
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }

  .container-section {
    padding-left: 30px;
    padding-right: 30px;
  }
  
}
