.page {
  margin: 5%;
  margin-left: 10%;
  margin-right: 10%;
}

.sub {
  font-size: 16px;
  color: #4d4d4d;
}

.head {
  font-weight: bold;
}

li {
  padding-bottom: 6px;
}

.boardText {
  font-size: 16px;
}

.vertLine {
  border-left: 6px solid #9ce2d3;
  border-left: 6px solid #9ce2d3;
  padding-right: 1%;
}

.outreachDates p {
  margin-bottom: 1%;
}

.localEvents p {
  margin-bottom: 1%;
  font-size: 16px;
  color: #4d4d4d;
}

.mentor-mentee-content {
  padding: 2% 10%;
  background-color: #ffffff;
  box-sizing: border-box;
}

.mentor-mentee-grid {
  display: flex;
  gap: 5%;
  justify-content: center;
  background-color: #ffffff;
  padding: 80px 0px;
}

.mentor-mentee-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 42px 64px;
  background-color: #dcf3ed;
  /* flex-basis: 40%;
  min-height: 300px;
  box-sizing: border-box; */
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 30px;
  background: var(--Cyan-2, #dcf3ed);
  box-shadow: 10px 10px 40px 0px rgba(0, 0, 0, 0.1);
}

.mentor-mentee-header {
  font-size: 28px;
  font-weight: bold;
  color: black;
  margin-bottom: 10px;
}

.mentor-mentee-text {
  font-size: 16px;
  padding: 10px 0;
  line-height: 1.5;
}

.requirements-header-mentor {
  font-size: 1em;
  margin-top: 16px;
  margin-bottom: 20px;
}

.requirements-list-mentor {
  margin-top: 8px;
  padding-left: 20px;
  font-size: 16px;
  line-height: 1.5;
}

.button-container-mentor {
  display: flex;
  justify-content: center;
  margin-top: auto;
}

.main {
  background-color: #000;
  overflow: hidden;
  position: relative;
}

.swiper {
  display: flex;
  overflow-x: visible;
  transition-property: transform;
  will-change: transform;
}

img {
  object-fit: contain;
}

.outreachSlides {
  padding-right: 5%;
  padding-left: 6%;
  color: grey;
}

.questionsImg {
  width: 150px;
  height: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 50%;
}

#back {
  all: unset;
  color: #ffffff;
  margin-bottom: 20px;
}

.full-width-banner {
  position: relative;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.full-width-banner2 {
  position: relative;
  height: auto;
  width: fit-content;
  margin: 0;
  padding: 0;
}

.full-width-banner3 {
  position: relative;
  height: auto;
  width: 100%;
  margin: 0;
  padding: 0;
}

.banner3 {
  width: 100%;
  object-fit: cover;
  filter: brightness(40%);
}

.banner4 {
  width: 100%;
  object-fit: cover;
}

.banner {
  width: 100%;
  height: auto;
  filter: brightness(40%);
}

.banner-text {
  position: absolute;
  top: 55%;
  left: 0;
  transform: translateY(-50%);
  color: white;
  text-align: left;
  z-index: 2;
  max-width: 40%;
  padding-left: 8%;
}

.banner-text3 {
  position: absolute;
  top: 45%;
  left: 0;
  transform: translateY(-50%);
  color: white;
  text-align: left;
  z-index: 2;
  width: 50%;
  padding-left: 8%;
}

.banner-header {
  font-size: 3em;
  font-weight: bold;
}

.section-title {
  font-size: 1.5em;
  font-weight: bold;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* padding-bottom: 40px; */
}

.testimonial-col {
  padding-bottom: 7%;
  min-width: 100%;
}

.testimonial-person {
  font-size: 16px;
  font-weight: bold;
}

.testimonial-row {
  padding-left: 18%;
}

.testimonial-card {
  margin-left: 1%;
  margin-right: 1%;
  width: 25%;
  padding-top: 2%;
  padding-bottom: 0.5%;
  padding-left: 2%;
  padding-right: 2%;
  background-color: #ffffff;
  border-color: #ffffff;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}

.content-row {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.column {
  flex: 1 1 0%;
  align-self: left;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 40px;
  border-right: 4px solid #9ce2d3;
  height: 100%;
}

.testimonial-title {
  margin-left: 15%;
  margin-bottom: 2%;
  padding-left: 55px;
  font-weight: bold;
}

.lunch-img {
  height: auto;
  width: 90%;
  border-radius: 20px;
}

.img-col {
  padding-left: 19%;
  padding-bottom: 50px;
}

.times-col {
  padding-top: 4.5%;
  margin-left: -2.5%;
}

.times-row {
  padding-top: 5%;
  padding-bottom: 2.5%;
  width: 100%;
}

.times-card {
  background-color: #ffffff;
  border-color: #ffffff;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  width: 60%;
}

.times-list {
  padding-left: 20%;
  padding-bottom: 8%;
  padding-top: 12%;
}

.times-content {
  font-size: small;
}

.times-text {
  padding-left: 15%;
  padding-bottom: 5%;
  font-weight: bold;
}

.questions-container {
  background-color: #f8f9fa;
}

.questions-container2 {
  background-color: #f8f9fa;
  width: 100%;
}

.questions-text1 {
  padding-left: 20%;
  font-size: x-large;
  padding-bottom: 1.5%;
}

.questions-text2 {
  padding-left: 20%;
  padding-bottom: 1%;
}

.questions-img {
  width: 75px;
  height: 75px;
  object-fit: cover;
  border-radius: 50%;
}

.questions-card {
  width: 25%;
  justify-content: center;
  border: #f8f9fa;
  background-color: #f8f9fa;
}

.questions-profile {
  justify-content: center;
  margin-left: 10%;
}

.cod {
  align-items: center;
  justify-content: center;
}

.text-content {
  align-self: left;
  color: #4d4d4d;
  font-size: 20px;
  font-weight: 400;
  word-wrap: break-word;
  height: 100%;
}

/* todo add new questions styling to other programs page
 currently in mentorship, can potentially automatically make other question sections mobile compatable*/
.questions-section {
  align-items: center;
  background: #f7f9f8;
  padding: 3%;
  padding-left: 8%;
}
.questions-section h2 {
  text-align: center;
}

.questions-section p {
  text-align: center;
}

.questions-cards-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
}

/* mobile styling - mentorship at the moment */
@media (max-width: 760px) {
  .mentor-mentee-grid {
    flex-direction: column; /* vertical stack of two columns*/
    align-items: center;
    gap: 20px;
  }

  .mentor-mentee-column {
    flex-basis: 90%;
    min-height: auto;
  }

  .mentor-mentee-header {
    font-size: 24px;
  }

  .mentor-mentee-text {
    font-size: 14px;
  }
  .question-card-container {
    flex-direction: column; /* stack people */
  }
}
