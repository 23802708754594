@import url(https://fonts.googleapis.com/css?family=Mulish:400,700);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Inter",
    "Rubik", "Lato", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior: none;
  height: 100%;
  overflow-y: scroll;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.navbar {
  position: absolute;
  top: 0;
  padding-left: 70px;
  padding-right: 70px;
  background-color: transparent !important;
  z-index: 50;
  width: 100%;
}

.toggle-button:hover {
  color: #9ce2d3 !important;
  transition: 0.5s ease;
}

.navbar .navbar-nav .nav-link:hover {
  color: #9ce2d3 !important;
  transition: 1s ease;
  text-decoration: underline;
  -webkit-text-decoration-color: #fff;
          text-decoration-color: #fff;
  text-decoration-thickness: 3px;
  font-size: 16.5px;
  font-weight: bolder;
}

.navbar .navbar-brand {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 20px;
}

.links {
  display: flex;
  flex-direction: row;
  width: 70%;
  justify-content: space-between;
}

.navbar .navbar-brand .brand-text {
  display: flex;
  flex-direction: row;
  margin-left: 10px;
  text-align: left;
  font-size: 14px;
}

.navbar .join-us-button .btn {
  background-color: transparent;
  color: white;
  border: 1px solid white;
}

.navbar .join-us-button .btn:hover {
  cursor: pointer;
  background-color: #9ce2d3 !important;
  font-weight: bolder;
  color: black;
}

.navbar-logo {
  padding-bottom: 1rem;
}

@media (max-width: 960px) {
  body {
    margin-top: 20%;
  }
  .navbar {
    position: relative;
    top: 0;
    z-index: 5;
    width: 100%;
    padding-inline: 40px;
    color: black;
    padding-left: 0px;
    padding-right: 0px;
  }

  .mobile-navbar {
    background-color: white !important;
    padding-inline: 30px;
  }

  .mobile-links {
    background-color: white !important;
    width: 100%;
    top: 70px;
    padding-bottom: 1.5rem;
  }

  .mobile-links .nav-link {
    color: black !important;
  }

  .mobile-links .nav-link:hover {
    color: #9ce2d3 !important;
  }

  .navbar .join-us-button {
    padding-top: 0.8rem;
  }

  .navbar .join-us-button .btn {
    background-color: black;
    color: white;
  }
  .toggle-button{
    color: black
  }
}

body {
  margin: 0; /* Avoids any default body margins */
  overflow-x: hidden; /* Prevents horizontal scrolling */
}

.container {
  max-width: 100%; /* Ensures content stays within the screen */
  overflow-x: hidden; /* Forces no overflow horizontally */
}

.home-container {
  padding-bottom: 20px;
  max-width: 100%;
}
/* 
.responsive-text {
  display: none;
} */

.hero-content{
  left: 35% !important;
}

.downward-arrow {
  position: absolute;
  bottom: 40px;  /* Adjust position from the bottom */
  left: 50%;
  transform: translateX(-50%);
  width: 50px;  /* Adjust size */
  height: auto;
  opacity: 0.8;
  transition: opacity 0.3s ease;
  animation: bounce 1.5s infinite;
  cursor: pointer;
}

/* Smooth hover effect */
.downward-arrow:hover {
  opacity: 1;
}

/* Bouncing animation */
@keyframes bounce {
  0%, 100% {
    transform: translateX(-50%) translateY(0);
  }
  50% {
    transform: translateX(-50%) translateY(10px);
  }
}

/* Show only for screens larger than 414px */
@media screen and (min-width: 415px) {
  .responsive-text {
    display: block;
  }
}

.hero-container {
  position: relative;
}

.hero-content {
  position: absolute;
  top: 50%;
  z-index: 1;
  padding-left: 4rem;
  text-align: left;
  color: #fff;
}

.hero-content h2 {
  font-size: 3rem;
  text-align: left;
  color: #fff;
  margin-left: 2rem;
  max-width: 800px;
  font-weight: bold;
}

.hero-content p {
  font-size: 1rem;
  text-align: left;
  max-width: 600px;
  margin: 0 auto;
  margin-left: 0;
}

.mission-image {
  width: 100%;
}

.semout-container {
  display: flex;
  flex-direction: row;
  align-content: left;
  justify-content: flex-start;
}

.semout-content {
  display: flex;
  flex-direction: column;
  align-content: left;
  justify-content: center;
  margin-top: 5em;
  margin-left: 10em;
  margin-bottom: 2em;
}

.semout-title {
  text-align: left;
  font-weight: bold;
  margin-bottom: 1em;
}

.semout-description {
  text-align: left;
  max-width: 800px;
}

/* work starts here */

.what-we-do-container {
  padding: 2em;
}

.what-we-do-header {
  text-align: left;
}

.what-we-do-title {
  font-weight: bold;
  margin-left: 4em;
}

.what-we-do-content {
  padding-inline: 8em;
  display: flex;
  flex-direction: row;
  grid-gap: 8em;
  gap: 8em;
  display: flex;
  justify-content: space-between;
  margin-inline: auto;
}

.what-we-do-column {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  align-items: flex-start;
  margin-inline: auto;
}

.what-we-do-image {
  width: 100%;
  height: auto;
}

.what-we-do-description,
.what-we-do-text {
  margin-inline: 2em;
}

.btn {
  border-radius: 5em;
  color: black;
  background-color: #9ce2d3;
  border-color: #9ce2d3;
}

.what-we-do-button {
  border-radius: 5em;
  color: black;
  background-color: #9ce2d3;
  border-color: #9ce2d3;
  margin-bottom: 2em;
  margin-left: 2em;
  width: 90%;
}

.btn:hover {
  background-color: black; /* Hover background color */
  color: white; /* Hover text color */
}

.call-to-action-container {
  display: flex;
  justify-content: center;
  text-align: center;
  padding-left: 4rem;
  padding-right: 4rem;
}

.get-involved {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.get-involved-header {
  text-align: left;
  font-weight: bold;
  margin-left: 5rem;
}

.get-involved-grid {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.get-involved-columno {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  background-color: #9ce2d3;
  min-height: 150px;
  border-radius: 25px;
  padding: 1.5rem;
  margin: 10px;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.15);
  justify-content: flex-start; /* Prevents stretching */
  min-height: auto; /* Ensures it only grows as needed */
}

.get-involved-columne {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  background-color: white;
  min-height: 150px;
  border-radius: 25px;
  padding: 1.5rem;
  margin: 10px;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.15);

  justify-content: flex-start; /* Prevents stretching */
  min-height: auto; /* Ensures it only grows as needed */
}

.get-involved-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: flex-start; /* Ensures everything inside is left-aligned */
  justify-content: flex-end;
  min-height: auto;

}



.button-name,
.button-description {
  display: flex;
  justify-content: flex-start;
  margin-top: auto; /* Pushes text to the bottom */
  align-self: flex-end;
}



.button-name {
  display: flex;
  justify-content: flex-start;
  font-weight: 800;
  text-align: left;
  margin-top: auto;
}

.carousel-wrapper {
  position: relative;
  width: 100%;
  max-width: 100%;
  height: 200px;
  margin: 0 auto;
}

.carousel img {
  width: 100%;
  height: 200px;
  object-fit: fill;
}

.carousel .control-arrow {
  padding: 0;
}

.carousel .control-dots {
  position: absolute;
  bottom: -30px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  color: black;
}

.carousel .control-dots .dot {
  background-color: black;
  color: black;
  opacity: 0.8;
}

.stats {
  display: flex;
  justify-content: center;
  align-items: center;
}

.stat-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 20px;
  text-align: center;
  margin-bottom: 1em;
}

.stat-item strong {
  font-weight: bold;
  color: #9ce2d3;
  font-size: 2em;
}

.stat-item span {
  font-weight: bold;
}

.alumni-section {
  background-color: #fafafa;
  width: 100%;
  padding: 3em 0;
}

.alumni-header {
  text-align: left;
  font-weight: bold;
  margin-left: 5em;
  margin-bottom: 1.5rem;
}

.testimony-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 8rem;
  padding-right: 8rem;
}

.testimony {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  background-color: #ffffff;
  border-radius: 2rem;
  padding: 2rem;
  margin: 0 20px;
}

.alumni-name {
  margin-bottom: 1rem;
  font-size: 1.2rem;
  font-weight: bold;
}

@media (max-width: 960px) {
  .hero-container .hero-content h2 {
    font-size: 1.8rem;
  }
}

@media (max-width: 760px) {
  .hero-container {
    position: relative;
    height: 100vh;
    overflow: hidden;
    margin-top: -20px;
  }

  .mission-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .hero-content {
    position: absolute;
    top: 40% !important;
    left: 35%;
    transform: translate(-50%, -50%);
    z-index: 1;
    text-align: left;
    color: #fff;
    padding: 20px;
    margin-inline: 1rem;
    margin-left: 0;
    top:40%;
  }

  .hero-container .hero-content h2 {
    font-size: 1.5rem;
    padding-top: 100px;
  }

  .hero-content p {
    font-size: 0.9rem;
  }

  .semout-title {
    margin-bottom: 0.5em;
    margin-left: -1rem;
  }

  .semout-content {
    margin-top: 2em;
    margin-inline: 4em;
    margin-bottom: 0rem;
  }

  .what-we-do-content {
    transform: translateY(-10px);
    flex-direction: column;
    align-items: center;
    padding-inline: 0em;
    padding-bottom: 1em;
    grid-gap: 0em;
    gap: 0em;
  }

  .what-we-do-button {
    border-radius: 5em;
    color: black;
    background-color: #9ce2d3;
    border-color: #9ce2d3;
    margin-bottom: 2em;
    margin-left: 1em;
    width: 90%;
  }

  .what-we-do-column {
    margin-left: 0;
    width: 100%;
  }

  .what-we-do-title {
    margin-left: 1rem;
  }

  .what-we-do-image {
    max-width: 100%;
  }

  .alumni-section {
    padding: 2em;
  }

  .alumni-header {
    margin-left: 0.5rem;
  }

  .testimony-container {
    flex-direction: column;
    padding-left: 0;
    padding-right: 0;
  }

  .testimony {
    margin: 20px;
  }

  .get-involved {
    padding-top: 2em;
    padding-bottom: 2em;
  }

  .get-involved-header {
    margin-left: 1.5em;
    margin-bottom: 0;
  }

  .call-to-action-container {
    display: flex;
    justify-content: center;
    width: 100%;
    float: left;
    flex-wrap: wrap;
    flex-direction: row;
    padding: 1.5em 2em;
  }

  .get-involved-grid {
    display: flex;
    width: auto;
    flex-wrap: wrap;
  }

  .get-involved-columno {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    background-color: #9ce2d3;
    min-height: 150px;
    border-radius: 25px;
    padding: 1.2rem;
    margin: 0.5em;
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.15);
    max-width: 45%;
  }

  .get-involved-columne {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    background-color: white;
    min-height: 150px;
    border-radius: 25px;
    padding: 1.2rem;
    margin: 0.5em;
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.15);
    max-width: 45%;
  }

  .arrow-img {
    margin-bottom: 1em;
  }

  .button-description {
    text-align: left;
    font-size: 12px;
  }

  .button-name {
    font-size: 18px;
    text-align: left;
  }

  .sponsor-container {
    padding: 2em;
  }

  .home-container {
    padding-bottom: 0px;
  }
}

h2 {
color: black
}

.containerPage{
  /* margin: 5%; */
  /* margin-top: 25px; */
  margin: 5%;
  margin-left: 12%;
  margin-right: 12% 
}

.container {
  display: flex;
  flex-wrap: wrap; /* This will allow items to wrap to the next line if they don't fit */
  justify-content: center; /* Adjust as per your design */
}

.rowC{
  display:flex; 
  flex-direction:row;
  columns: 2 auto;

}

.fakeCard {
  margin-bottom: 200px;
}

/* .col {
  float: left
} */

.col {
  flex: 1 1;
  margin: 10px; /* Example margin, adjust as needed */
  box-sizing: border-box; /* Ensures padding and border are included in the width */
}

.sponsorStyle {
  margin-top: 0px;
  margin-bottom: 60px;
}

.faculty{
  margin-left:5%;
  margin-right:5%

}

.extra BoardCard {
  color: white
}
.hero {
  flex-shrink: 0;
  color: white;
}

.hero-text {
  position: absolute;
  top: 30%;
  margin-top: 5%;
  /* left: 0; */
  /* transform: translateY(-50%); */
  color: white;
  text-align: left;
  z-index: 2;
  /* max-width: 40%; */
  margin-right: 60%;
  padding-left: 8%;
}

.hero-image {
  filter: brightness(40%);
  object-fit: cover;
  overflow: hidden;
  width: 100vw;
  height:100vh;

}

.hero-text a {
  color: white;
  text-decoration: underline;
}

.hero-text a:hover {
  box-shadow: 0px 1px 1px 1px rgb(68, 68, 68);
}

.downward-arrow {
  position: absolute;
  bottom: 40px;  /* Adjust position from the bottom */
  left: 50%;
  transform: translateX(-50%);
  width: 50px;  /* Adjust size */
  height: auto;
  opacity: 0.8;
  transition: opacity 0.3s ease;
  animation: bounce 1.5s infinite;
  cursor: pointer;
}

/* Smooth hover effect */
.downward-arrow:hover {
  opacity: 1;
}

/* Bouncing animation */
@keyframes bounce {
  0%, 100% {
    transform: translateX(-50%) translateY(0);
  }
  50% {
    transform: translateX(-50%) translateY(10px);
  }
}

.carousel-wrapper {
  position: relative;
  width: 100%;
  max-width: 100%;
  height: 150px;
  margin: 0 auto;
}

.benefits-section {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 10vw;
  align-items: center;
  grid-gap: 160px;
  gap: 160px;
  background: var(--3, #fff);
}

.benefits-header {
  color: #000;
  font-family: Mulish;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}

.benefits-text {
  align-self: stretch;
  color: #000;
  font-family: Mulish;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.hero-img{
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
.hero img {
  object-fit: cover; /* Ensures the image covers the entire area without distortion */
}

.hero hi{
  min-height: 400px;
}




@media screen and (max-width: 1100px) {
  .benefits-section {
    flex-direction: column;
    flex-direction: column-reverse;
    grid-gap: 20px;
    gap: 20px;
  }
}

/* Text Responsive smaller screen */
@media screen and (max-width: 768px) {
  .top-section {
    flex-direction: column;
    padding: 50px 50px 0px 50px;
  }

  .top-header,
  .benefits-header {
    font-size: 34px;
  }

  .top-text,
  .benefits-text {
    font-size: 15px;
  }
}

@media screen and (max-width: 480px) {
  .top-header,
  .benefits-header {
    font-size: 20px;
  }

  .top-text,
  .benefits-text {
    font-size: 13px;
  }
}
#sponsorsTable {
  border-style: hidden;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
}

th {
  font-weight: 900;
  font-size: 24px;
  padding-bottom: 20px !important;
}

tbody tr:nth-child(odd) {
  background-color: rgb(156, 226, 211, 0.6);
  border-style: hidden;
}

tbody tr td {
  padding: 5px 15px !important;
}

td {
  vertical-align: middle !important;
}

td:first-child {
  border-radius: 20px 0 0 20px;
}

td:last-child {
  border-radius: 0 20px 20px 0;
}

.center {
  text-align: center;
}

#sponsorsTable img {
  width: 60px;
  height: 40px;
  display: block;
  margin: auto;
}

.container-section {
  background-color: #fbfbfb;
  padding: 40px 10px;
  display: flex;
  justify-content: space-between;
  grid-gap: 20px;
  gap: 20px;
  flex-wrap: wrap;
}
.description {
  color: #212529;
  font-family: Mulish;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.container-header {
  color: #212529;
  font-family: Mulish;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  text-align: left;
}

.container-subheader {
  color: #212529;
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  text-align: left;
  margin-bottom: 0;
}

.container1 {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  padding: 20px;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: calc(25% - 20px);
  max-width: calc(25% - 10px);
  margin-left: 40px;
}

.container2 {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  padding: 20px;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: calc(40% - 20px);
  max-width: calc(40% - 10px);
  margin-right: 5px;
}

@media screen and (min-width: 571px) {
  .responsive-text {
    display: block;
  }
}

.container3 {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  padding: 20px;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: calc(25% - 20px);
  max-width: calc(25% - 10px);
  margin-right: 40px;
}

@media (max-width: 768px) {
  .container1,
  .container2,
  .container3 {
    float: none;
    display: block;
    flex: none;
    max-width: 100%;
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }

  .container-section {
    padding-left: 30px;
    padding-right: 30px;
  }
  
}

.sponsor-container {
  display: flex;
  padding-top: 56px;
  padding-left: 5%;
  padding-right: 5%;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 10px;
  gap: 10px;
  align-self: stretch;
}

.sponsor-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.sponsor-title {
  text-align: left;
  padding-left: 0.5em;
  font-weight: bold;
  color: #000;
  font-family: "Mulish", sans-serif;
  font-size: 36px;
  font-style: normal;
  line-height: 150%;
}

.sponsor-carousel-container {
  object-fit: cover;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 20px;
}

@media (max-width: 760px) {
  .sponsor-container {
    padding: 2em;
    padding-inline: 3rem;
  }

  .sponsor-title {
    font-size: 30px;
  }
  .sponsor-carousel-container {
    padding-top: 0;
  }
}

/* .home-container {
  margin: 5%;
}

.hero-container, .mission-container, .call-to-action-container {
  margin: 5% 0;
}

.hero-container {
  overflow: hidden;
}

#home-illustration {
  width: 40%;
  float: right;
}

.call-to-action-container {
  margin-bottom: 15%;
  text-align: center;
} */

/* react-bootstrap button style overriding */
/* .btn {
  border-radius: 5em;
}

.btn-primary {
  color: black;
  background-color: #9CE2D3;
  border-color: #9CE2D3;
}

.headerName {
  font-size: 50px;
} */

body {
  margin: 0;
  overflow-x: hidden;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.container {
  width: 100vw;
  max-width: 100%;
  overflow-x: hidden;
}

.column {
  float: left;
  padding: 30px;
}

.left {
  padding-left: 100px;
  width: 40%;
}

.right {
  width: 60%;
  text-align: center;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

.row {
  margin-left: auto;
  margin-right: auto;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}

h2 {
  color: black;
}

.hero-container {
  position: relative;
}

.hero-content {
  position: absolute;
  top: 50%;
  left: 30%;
  transform: translate(-50%, -50%);
  z-index: 1;
  text-align: left;
  color: #fff;
}
/* 
.responsive-text {
  display: none;
} */
.responsive-title {
  font-size: 3rem;
  position: static;
  text-align: left;
}

.downward-arrow {
  position: absolute;
  bottom: 40px;  /* Adjust position from the bottom */
  left: 50%;
  transform: translateX(-50%);
  width: 50px;  /* Adjust size */
  height: auto;
  opacity: 0.8;
  transition: opacity 0.3s ease;
  animation: bounce 1.5s infinite;
  cursor: pointer;
}

/* Smooth hover effect */
.downward-arrow:hover {
  opacity: 1;
}

/* Bouncing animation */
@keyframes bounce {
  0%, 100% {
    transform: translateX(-50%) translateY(0);
  }
  50% {
    transform: translateX(-50%) translateY(10px);
  }
}


/* Show only for screens larger than 414px */
@media screen and (min-width: 761px) {
  .responsive-text {
    display: block;
  }
}

.hero-content h2 {
  font-size: 3rem;
  text-align: left;
  color: #fff;
  margin-left: 0;
  max-width: 800px;
  font-weight: bold;
}

.hero-content p {
  font-size: 1rem;
  text-align: left;
  max-width: 600px;
  margin: 0 auto;
  margin-left: 0;
}

.containerPage {
  margin: 5%;
  margin-left: 12%;
  margin-right: 12%;
}

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.rowC {
  display: flex;
  flex-direction: row;
}

.fakeCard {
  margin-bottom: 200px;
}

.col {
  flex: 1 1;
  margin: 10px;
  box-sizing: border-box;
}

.sponsorStyle {
  margin-top: 0px;
  margin-bottom: 60px;
}

.faculty {
  margin-left: 5%;
  margin-right: 5%;
}

.extra BoardCard {
  color: white;
}

/* Mobile Devices */
@media (max-width: 600px) {
  .containerPage {
    margin-left: 5%;
    margin-right: 5%;
  }

  .rowC {
    flex-direction: row;
    flex-wrap: wrap;
    /* Allow wrapping for multiple rows */
  }

  .col {
    flex: 1 0 50%;
    /* Each column takes up 50% width */
    box-sizing: border-box;
    /* Ensure padding and border are included in the width */
    padding: 5px;
    /* Add some spacing between columns */
  }

  .right-sidebar {
    display: none;
    /* Hide the sidebar on smaller screens */
  }

  p {
    font-size: smaller;
  }

  .wwa {
    display: none;
  }
  .wwa2{
    display: flex !important;
  }
}

/* Tablets */
@media (min-width: 601px) and (max-width: 1140px) {
  .containerPage {
    margin-left: 8%;
    margin-right: 8%;
  }

  .col {
    flex: 1 1 50%;
    /* Columns take up half the width */
  }

  .right-sidebar {
    display: none;
    /* hide the sidebar on mid-sized screens */
  }
}

/* Desktops and larger screens */
@media (min-width: 1140px) {
  .containerPage {
    margin-left: 12%;
    margin-right: 12%;
  }

  .right-sidebar {
    display: flex;
    /* ensure it's visible on large screens */
    float: right;
    height: 100%;
    flex-direction: column;
    align-items: flex-end;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }

  .rowC {
    flex-direction: row;
    columns: 2 auto;
  }

  .col {
    flex: 1 1;
    /* Default flexibility */
  }
  .hero-container{
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }
  .hero-container img {
    /* width: 100%;
    height: 100%; */
    object-fit: cover; /* Ensures the image covers the entire area without distortion */
  }
}

.boardImg{
  width: 150px;
  height:150px;
  object-fit: cover;
  border-radius: 50%;
  margin-left: 18%
}

a {
  color: black;
  text-decoration: none
}
a:hover {
  transition: .5s ease;
  /* color:#9CE2D3; */
  color:darkgray;
  /* font-weight: bold; */

  text-decoration: none
}


.overlay {
  position: absolute;
  /* top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%; */
  opacity: 0;
  transition: .5s ease;
  background-color: white;
}

.container:hover .overlay {
  opacity: 1;
}

.emailImg:hover{
  opacity: 0;
}


.custom-modal-style {
  max-width: none;
  width: 90%;
  font-weight: bold;
}

.subteam-name {
  font-weight: bold;
}
.memberContainer {
  width: 30%;
  height: 80%;
  border: none;
  min-width: 200px;
  cursor: pointer;
  /* Add cursor style for better UX */
}

/* Medium screens (e.g., tablets) */
@media (min-width: 600px) {
  .memberContainer {
    width: 50%;
    /* Two columns on medium screens */
  }
}

/* Large screens (e.g., desktops) */
@media (min-width: 1024px) {
  .memberContainer {
    width: 30%;
    /* Three columns on large screens */
  }
}

/* Extra large screens */
@media (min-width: 1440px) {
  .memberContainer {
    width: 20%;
    /* Five columns on extra large screens */
  }
}


.fadeIn {
  animation: fadeInAnimation 1s forwards;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
    /* Adjust this value for more or less vertical movement */
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.imageStyle {
  position: relative;
  animation-duration: 1s;
  /* Duration of the animation */
  animation-fill-mode: forwards;
  /* Ensures the component retains the style from the last keyframe when the animation ends */
  animation-name: fadeInUp;
  /* Name of the animation */
  will-change: opacity, transform;
  /* Optimizes animations by letting the browser know what properties to expect changes in */
  width: 12vw;
  /* 10% of the viewport width */
  height: 12vw;
  /* Set height equal to width for 1:1 aspect ratio */
  border-radius: 10%;
  object-fit: cover;
  object-position: center;
  /* Adjust if needed */
}

.profileImageStyle {
  width: 12vw;
  /* 10% of the viewport width */
  aspect-ratio: 1 / 1;
  object-fit: cover;
  border-radius: 10%;
}

.imageStyle {
  width: 80%;
  /* Ensures the image scales with the container */
  height: auto;
  /* Maintains the aspect ratio */
  border-radius: 10%;
  object-fit: cover;
  object-position: center;
  /* Center the image within its container */
}


.modalContent {
  display: flex;
  align-items: center;
}

.modalContent .imageStyle {
  animation: none;
}


.modalTextContent {
  padding: 20px;
}

.modalTextStyle {
  margin-top: 2px;
  margin-bottom: 2px;
}

.position {
  font-size: smaller;
  color: gray;
  padding-right: 35px;
  padding-top: 5px;
  font-weight: bold;
}

.name {
  padding-bottom: 32px;
  font-weight: bold;
}

.imageContainer {
  position: relative;

}

.coffeeChatButton {
  position: absolute;
  bottom: 5px;
  right: 25px;
  width: 50px;
  /* Adjust based on the overall circle size in Figma */
  height: 50px;
  border-radius: 50%;
  background-color: white;
  border: 1px solid #73CFBB;
  cursor: pointer;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  /* transition: transform 0.3s; */
  transition: width 0.3s, height 0.3s, border-radius 0.3s;
}

.coffeeChatButton img {
  position: absolute;
  /* max-width: 100%;
  max-height: 100%; */
}

/* .coffeeChatButton:hover {
  transform: scale(1.5); 
} */

.coffeeChatButton.expanded {
  width: 165px;
  /* Adjust this size based on your expanded button design */
  height: 50px;
  border-radius: 25px;
}
/* Base style for very small screens */
/* .members-grid {
  display: grid;
  grid-template-columns: 1fr; */
/* 1 column for the smallest screens */
/* gap: 10px; */
/* } */

/* Intermediate screens (e.g., large phones/small tablets) */
@media (max-width: 768px) {
  .members-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* 2 columns for intermediate screens */
  }
}

/* Medium screens (e.g., tablets) */
@media (min-width: 768px) {
  .members-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* 3 columns for medium screens */
  }
}

/* Large screens (e.g., desktops) */
@media (min-width: 1024px) {
  .members-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    /* 4 columns for large screens */
  }
}
.floating-menu {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #f8f9fa;
  border: 1px solid #ddd;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1000;
}

.floating-menu.open {
  border-radius: 10px;
  width: 200px;
  height: auto;
  padding: 20px;
  flex-direction: column;
  align-items: left;
}

.menu-icon {
  font-size: 30px;
  transition: all 0.3s ease;
}

.menu-icon.top-left {
  position: absolute;
  top: 10px;
  left: 10px;
}

.floating-menu .nav {
  display: block;
  margin-top: 20px;
}

/* Mobile Devices */
@media (max-width: 600px) {}

/* Tablets */
@media (min-width: 601px) and (max-width: 1140px) {}

/* Desktops and larger screens */
@media (min-width: 1140px) {
  .floating-menu {
    display: None;
  }
}
/* .custom-progress-bar .progress-bar {
  background-color: #9CE2D3;
  height: 10;
} */
body {
  overflow-x: hidden;
}

.hero {
  flex-shrink: 0;
  flex-direction:column;
  color: white;
  min-height: 300px;
  /*pushes everything down (doesnt help the text) */
}

.hero-text {
  position: absolute;
  /* top: 15%; */
  margin-top: 30%;
  /* left: 0; */
  /* transform: translateY(-50%); */
  color: white;
  text-align: left;
  z-index: 2;
  max-width: 40%;
  margin-right: 60%;
  padding-left: 8%;
}

.hero-image {
  filter: brightness(40%);
  object-fit: cover;
  overflow: hidden;
  width: 100%;
  max-height: 700px;
}

.hero-text a {
  color: white;
  text-decoration: underline;
}


.downward-arrow {
  position: absolute;
  bottom: 40px;  /* Adjust position from the bottom */
  left: 50%;
  transform: translateX(-50%);
  width: 50px;  /* Adjust size */
  height: auto;
  opacity: 0.8;
  transition: opacity 0.3s ease;
  animation: bounce 1.5s infinite;
  cursor: pointer;
}

/* Smooth hover effect */
.downward-arrow:hover {
  opacity: 1;
}

/* Bouncing animation */
@keyframes bounce {
  0%, 100% {
    transform: translateX(-50%) translateY(0);
  }
  50% {
    transform: translateX(-50%) translateY(10px);
  }
}

@media screen and (min-width: 731px) {
  .responsive-text {
    display: block;
  }
}

.hero-text a:hover {
  box-shadow: 0px 1px 1px 1px rgb(68, 68, 68);
}

.what-we-do {
  font-size: 30px;
  font-weight: bold;
  text-align: left;
  margin-top: 5%;
  margin-bottom: 0;
  margin-left: 5%;
  max-width: 1000px;
  width: 100%;
}

.card {
  position: relative;
  transition: transform 0.3s, box-shadow 0.3s;
  border: none;
  box-shadow: none;
}

.card:hover .image {
  filter: brightness(50%);
  transition: filter 0.3s, transform 0.3s;
}

.card:hover .card-title {
  color: #9ce2d3;
}

.image {
  display: block;
  width: 100%;
  aspect-ratio: 1 / 0.85;
  object-fit: cover;
  border-radius: 10px;
  transition: filter 0.3s, transform 0.3s;
}

.card-title {
  padding-top: 10px;
  font-size: 18px;
  font-weight: bold;
  text-decoration: underline;
  color: black;
  transition: color 0.3s ease;
}

.card-title:hover {
  color: #9ce2d3;
  text-decoration: underline;
}

.upcoming-title {
  font-size: 30px;
  font-weight: bold;
  text-align: left;
  margin: 5%;
  max-width: 1000px;
  width: 100%;
}

.calendar-wrapper {
  margin: 0 auto;
  width: 100%;
}

.calendar-wrapper iframe {
  width: 100%;
  border: solid 1px #777;
  height: 600px;
}

.calendar-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 5% 5% 5%;
  max-width: 1000px;
}

.button-display {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  margin-right: 30px;
}

.event-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.events-grid {
  margin: 4% 5%;
  overflow-x: hidden;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 30px;
  gap: 30px;
  max-width: 1000px;
}

.item1 {
  grid-column-start: 1;
  grid-column-end: 2;
  background: var(--Cyan-2, #dcf3ed);
  border-radius: 20px;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
}

.item1 h1 {
  color: #000;

  /* Sub Title - 1 */
  font-family: Mulish;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  /* 60px */
  margin-left: 20%;
  text-align: left;
}

.item1 p {
  color: #000;
  /* Header-Paragraph */
  font-family: Mulish;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin-left: 20%;
  text-align: left;
}

@media (max-width: 1300px){
  .hero-text{
    margin-right: 0 !important;
    max-width: 80% !important;
  }
}

@media (max-width: 1000px) {
  .events-grid {
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    margin: 4% 5%;
  }

  .item1 {
    grid-column-start: auto;
    grid-column-end: auto;
  }

  .item1 h1,
  .item1 p {
    margin: 0 auto;
  }

  .hero-text {
    position: absolute;
    /* top: 15%; */
    margin-top: 5% 4%;
    /* left: 0; */
    /* transform: translateY(-50%); */
    color: white;
    text-align: left;
    z-index: 2;
    /* max-width: 40%; */
    margin-right: 10%;
    padding-left: 8%;
  }
}

.profile-pic {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
}

.card-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 2rem;
}

.name-text {
  font-weight: bold;
}

.email-text {
  margin-left: 2rem;
}
.page {
  margin: 5%;
  margin-left: 10%;
  margin-right: 10%;
}

.sub {
  font-size: 16px;
  color: #4d4d4d;
}

.head {
  font-weight: bold;
}

li {
  padding-bottom: 6px;
}

.boardText {
  font-size: 16px;
}

.vertLine {
  border-left: 6px solid #9ce2d3;
  border-left: 6px solid #9ce2d3;
  padding-right: 1%;
}

.outreachDates p {
  margin-bottom: 1%;
}

.localEvents p {
  margin-bottom: 1%;
  font-size: 16px;
  color: #4d4d4d;
}

.mentor-mentee-content {
  padding: 2% 10%;
  background-color: #ffffff;
  box-sizing: border-box;
}

.mentor-mentee-grid {
  display: flex;
  grid-gap: 5%;
  gap: 5%;
  justify-content: center;
  background-color: #ffffff;
  padding: 80px 0px;
}

.mentor-mentee-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 42px 64px;
  background-color: #dcf3ed;
  /* flex-basis: 40%;
  min-height: 300px;
  box-sizing: border-box; */
  align-items: flex-start;
  grid-gap: 16px;
  gap: 16px;
  flex: 1 0;
  align-self: stretch;
  border-radius: 30px;
  background: var(--Cyan-2, #dcf3ed);
  box-shadow: 10px 10px 40px 0px rgba(0, 0, 0, 0.1);
}

.mentor-mentee-header {
  font-size: 28px;
  font-weight: bold;
  color: black;
  margin-bottom: 10px;
}

.mentor-mentee-text {
  font-size: 16px;
  padding: 10px 0;
  line-height: 1.5;
}

.requirements-header-mentor {
  font-size: 1em;
  margin-top: 16px;
  margin-bottom: 20px;
}

.requirements-list-mentor {
  margin-top: 8px;
  padding-left: 20px;
  font-size: 16px;
  line-height: 1.5;
}

.button-container-mentor {
  display: flex;
  justify-content: center;
  margin-top: auto;
}

.main {
  background-color: #000;
  overflow: hidden;
  position: relative;
}

.swiper {
  display: flex;
  overflow-x: visible;
  transition-property: transform;
  will-change: transform;
}

img {
  object-fit: contain;
}

.outreachSlides {
  padding-right: 5%;
  padding-left: 6%;
  color: grey;
}

.questionsImg {
  width: 150px;
  height: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 50%;
}

#back {
  all: unset;
  color: #ffffff;
  margin-bottom: 20px;
}

.full-width-banner {
  position: relative;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.full-width-banner2 {
  position: relative;
  height: auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0;
  padding: 0;
}

.full-width-banner3 {
  position: relative;
  height: auto;
  width: 100%;
  margin: 0;
  padding: 0;
}

.banner3 {
  width: 100%;
  object-fit: cover;
  filter: brightness(40%);
}

.banner4 {
  width: 100%;
  object-fit: cover;
}

.banner {
  width: 100%;
  height: auto;
  filter: brightness(40%);
}

.banner-text {
  position: absolute;
  top: 55%;
  left: 0;
  transform: translateY(-50%);
  color: white;
  text-align: left;
  z-index: 2;
  max-width: 40%;
  padding-left: 8%;
}

.banner-text3 {
  position: absolute;
  top: 45%;
  left: 0;
  transform: translateY(-50%);
  color: white;
  text-align: left;
  z-index: 2;
  width: 50%;
  padding-left: 8%;
}

.banner-header {
  font-size: 3em;
  font-weight: bold;
}

.section-title {
  font-size: 1.5em;
  font-weight: bold;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* padding-bottom: 40px; */
}

.testimonial-col {
  padding-bottom: 7%;
  min-width: 100%;
}

.testimonial-person {
  font-size: 16px;
  font-weight: bold;
}

.testimonial-row {
  padding-left: 18%;
}

.testimonial-card {
  margin-left: 1%;
  margin-right: 1%;
  width: 25%;
  padding-top: 2%;
  padding-bottom: 0.5%;
  padding-left: 2%;
  padding-right: 2%;
  background-color: #ffffff;
  border-color: #ffffff;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}

.content-row {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.column {
  flex: 1 1;
  align-self: left;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  grid-gap: 40px;
  gap: 40px;
  border-right: 4px solid #9ce2d3;
  height: 100%;
}

.testimonial-title {
  margin-left: 15%;
  margin-bottom: 2%;
  padding-left: 55px;
  font-weight: bold;
}

.lunch-img {
  height: auto;
  width: 90%;
  border-radius: 20px;
}

.img-col {
  padding-left: 19%;
  padding-bottom: 50px;
}

.times-col {
  padding-top: 4.5%;
  margin-left: -2.5%;
}

.times-row {
  padding-top: 5%;
  padding-bottom: 2.5%;
  width: 100%;
}

.times-card {
  background-color: #ffffff;
  border-color: #ffffff;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  width: 60%;
}

.times-list {
  padding-left: 20%;
  padding-bottom: 8%;
  padding-top: 12%;
}

.times-content {
  font-size: small;
}

.times-text {
  padding-left: 15%;
  padding-bottom: 5%;
  font-weight: bold;
}

.questions-container {
  background-color: #f8f9fa;
}

.questions-container2 {
  background-color: #f8f9fa;
  width: 100%;
}

.questions-text1 {
  padding-left: 20%;
  font-size: x-large;
  padding-bottom: 1.5%;
}

.questions-text2 {
  padding-left: 20%;
  padding-bottom: 1%;
}

.questions-img {
  width: 75px;
  height: 75px;
  object-fit: cover;
  border-radius: 50%;
}

.questions-card {
  width: 25%;
  justify-content: center;
  border: #f8f9fa;
  background-color: #f8f9fa;
}

.questions-profile {
  justify-content: center;
  margin-left: 10%;
}

.cod {
  align-items: center;
  justify-content: center;
}

.text-content {
  align-self: left;
  color: #4d4d4d;
  font-size: 20px;
  font-weight: 400;
  word-wrap: break-word;
  height: 100%;
}

/* todo add new questions styling to other programs page
 currently in mentorship, can potentially automatically make other question sections mobile compatable*/
.questions-section {
  align-items: center;
  background: #f7f9f8;
  padding: 3%;
  padding-left: 8%;
}
.questions-section h2 {
  text-align: center;
}

.questions-section p {
  text-align: center;
}

.questions-cards-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  grid-gap: 30px;
  gap: 30px;
}

/* mobile styling - mentorship at the moment */
@media (max-width: 760px) {
  .mentor-mentee-grid {
    flex-direction: column; /* vertical stack of two columns*/
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
  }

  .mentor-mentee-column {
    flex-basis: 90%;
    min-height: auto;
  }

  .mentor-mentee-header {
    font-size: 24px;
  }

  .mentor-mentee-text {
    font-size: 14px;
  }
  .question-card-container {
    flex-direction: column; /* stack people */
  }
}

.body {
  background-color: #f9f9f9cb;
  text-align: left;
}

.title {
  text-align: center;
  align-items: center;
  box-sizing: content-box;
  padding: 50px;
  padding-top: 320px;
  padding-right: 240px;
  padding-bottom: 200px;
  display: flex;
  justify-content: center;
}

* {
  font-family: Mulish, sans-serif;
  font-weight: 450;
}

.hero-image-discussion {
  /* Use "linear-gradient" to add a darken background effect to the image (photographer.jpg). This will make the text easier to read */
  background-image: url(/static/media/communityhero.f8df744b.jpg);

  /* Set a specific height */
  width: 100%;
  height: 100%;
  max-height: 700px;

  /* Position and center the image to scale nicely on all screens */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.header {
  text-align: left;
  align-items: center;
  box-sizing: content-box;
  font-size: 20px;
  width: 100%;
  background-color: #ffffff;
  padding: 32px;
  border-radius: 8px;
  box-shadow: 0.1rem 0.1rem 1rem rgba(0, 0, 0, 0.1);
}

/* DEMO-SPECIFIC STYLES */
.typewriter h1 {
  /* color: #fff; */
  color: rgba(0, 0, 0);
  font-family: Mulish, sans-serif;
  font-size: 45px;
  overflow: hidden;
  /* Ensures the content is not revealed until the animation */
  border-right: 0.15em solid rgb(0, 255, 162);
  /* The typwriter cursor */
  white-space: nowrap;
  /* Keeps the content on a single line */
  margin: 0 auto;
  /* Gives that scrolling effect as the typing happens */
  letter-spacing: 0.15em;
  /* Adjust as needed */
  animation: typing 2.5s steps(30, end), blink-caret 0.5s step-end infinite;
}

/* The typing effect */
@keyframes typing {
  from {
    width: 0;
  }

  to {
    width: 720px;
  }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }

  50% {
    border-color: rgb(152, 216, 193);
  }
}

/*CARDS*/

* {
  box-sizing: border-box;
}

body {
  font-family: Mulish, sans-serif;
  margin: 0;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, 17rem);
  grid-gap: 2rem;
  gap: 2rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.item {
  position: relative;
  height: 25rem;
  background-color: lightGrey;
  overflow-y: hidden;
  box-shadow: 0.1rem 0.1rem 1rem rgba(0, 0, 0, 0.1);
  overflow-y: hidden;
  border-radius: 8px;
}

.item h3 {
  margin: 0;
  display: block;
  background-color: rgb(255, 255, 255);
  padding: 24px;
  transform: translate3d(0, -100%, 0);
  transition: transform 300ms;
  font-size: 24px;
  overflow-y: hidden;
}

.item a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.item a:hover ~ .item__overlay,
.item a:focus ~ .item__overlay {
  transform: translate3d(0, 0, 0);
}

.item a:hover ~ .item__overlay h3,
.item a:focus ~ .item__overlay h3 {
  transform: translate3d(0, 0, 0);
  overflow-y: hidden;
}

.item a:hover ~ .item__overlay .item__body,
.item a:focus ~ .item__overlay .item__body {
  opacity: 1;
  overflow-y: hidden;
}

.card-img-div {
  width: 100%;
  height: 100%;
  display: block;
  max-width: 100%;
  overflow-y: hidden;
}

.card-img {
  width: 550px;
  overflow: hidden;
}

.item__overlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  position: absolute;
  width: 100%;
  font-size: 10px;
  top: 0;
  transition: transform 300ms;
  background-color: rgb(224, 255, 243);
  transform: translate3d(0, 100%, 0);
}

.item__body {
  flex-grow: 1;
  font-size: 18px;
  padding: 24px;
  opacity: 0;
  transition: opacity 500ms 100ms;
}

.item__body p {
  margin: 0;
}

/* SLIDES */
.carousel {
  width: 50%;
  height: 50%;
  margin: auto;
}

.wicc-away {
  display: flex;
  flex-direction: row;
}

.image-caption-container {
  display: flex;
  margin-top: 32px;
  padding: 8px;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  background-color: #ffffff;
}

/* Image styles */
.image-caption-container img {
  max-width: 90%;
  height: 100%;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  flex: 1 1;
  border-radius: 8px;
}

/* Caption styles */
.image-caption-container .caption {
  flex: 1 1;
  max-width: 50%;
  text-align: left;
  background-color: #ffffff;
  padding: 32px;
  border-radius: 8px;
  font-family: Mulish, sans-serif;
}

.carousel-item {
  padding: 32px 0px 32px 32px;
}

.dropdown-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: transparent;
  width: 100%;
  border:0;
  margin: 0.5em;
  padding:0;
  align-items: center;
}

.dropdown-content {
  margin: 0.5em;
}

.contact {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1em 0;
  padding: 10px;
}

.contact-details {
  display: flex;
  flex-direction: column;
  margin-left: 1em;
}

.profile-pic {
  width: 6em; /* Adjust width as needed, ensure width and height are equal for a perfect circle */
  height: 6em; /* Adjust height to be the same as width */
  border-radius: 50%; /* This makes the image round */
  object-fit: cover; /* This ensures the image covers the space without stretching */
  border: 2px solid #fff; /* Optional: adds a white border around the image */
}

/* General Page Styles */
.page {
  margin: 5% 10%;
  padding: 80px 116px 0;
}

.sub,
.localEvents p {
  font-size: 90%;
  color: #4d4d4d;
}

.imagediv{
  margin-top: 0 !important;
  padding: 0 !important;
}

.head {
  font-weight: bold;
}

/* Lists */
li {
  padding-bottom: 6px;
}

/* Banner and Images */
.full-width-banner {
  position: relative;
  width: 100%;
  margin: 0;
  padding: 0;
}

.banner {
  width: 100%;
  height: auto;
  filter: brightness(50%);
}

.banner-text {
  position: absolute;
  top: 60%;
  left: 0;
  transform: translateY(-50%);
  color: white;
  text-align: left;
  z-index: 2;
  max-width: 40%;
  padding-left: 8%;
}

.banner-header,
.section-title {
  font-weight: bold;
}

.banner-header {
  font-size: 3em;
}

.section-title {
  font-size: 1.5em;
}

/* Layouts */
.container,
.swiper,
.outreachSlides,
.column {
  display: flex;
}

.swiper {
  overflow-x: visible;
  transition-property: transform;
  will-change: transform;
}

.container {
  width: 100%;
  flex-direction: row;
  margin: 0;
  padding: 0;
}

.divrowa:hover {
  color: #000000;
  transition: 0.3s ease;
  text-decoration: underline;
  -webkit-text-decoration-color: #9ce2d3;
          text-decoration-color: #9ce2d3;
  text-decoration-thickness: 3px;
  font-weight: bolder;
}


.divrow{
  margin: 0 4% !important;
  grid-gap: 3% !important;
  gap: 3% !important;
}
.column {
  flex: 1 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  grid-gap: 40px;
  gap: 40px;
  border-right: 4px solid #9ce2d3;
  height: 100%;
  padding-left: 2rem;
  padding-right: 3rem;
}

.column-header {
  color: #212529;
  font-size: 94px;
  font-weight: 700;
}

.text-content {
  align-self: left;
  color: #4d4d4d;
  font-size: 18px;
  font-weight: 400;
  word-wrap: break-word;
  height: 100%;
}

/* Misc */
img {
  object-fit: contain;
}

.questionsImg {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 50%;
}

/* .container {
  padding-top: 56px;
  padding-bottom: 56px;
} */

.h3title {
  padding-bottom: 48px !important;
}

.hero {
    flex-shrink: 0;
    color: white;
  }
  
  .hero-text {
    position: absolute;
    top: 10%;
    margin-top: 5%;
    /* left: 0; */
    /* transform: translateY(-50%); */
    color: white;
    text-align: left;
    z-index: 2;
    /* max-width: 40%; */
    margin-right: 60%;
    padding-left: 8%;
  }
  
  .hero-image {
    filter: brightness(40%);
    object-fit: cover;
    overflow: hidden;
    width: 100%;
    max-height: 700px;
  }
  
  .hero-text a {
    color: white;
    text-decoration: underline;
  }
  
  .hero-text a:hover {
    box-shadow: 0px 1px 1px 1px rgb(68, 68, 68);
  }
.timeline-item {
  display: flex;
  align-items: flex-start; /* Vertically align items */
  position: relative;
  width: 100%;
  max-width: 900px;
}

.timeline-item.alternate {
  flex-direction: row-reverse; /* Flip layout for alternate items */
}

.timeline-image-wrapper {
  flex: 1 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.timeline-marker {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin: 0 1rem;
  min-width: 40px;
}

.timeline-circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #9CE2D3;
}

.timeline-line {
  width: 4px;
  height: 260px;
  background-color: hsl(0, 0%, 87%);
}

.timeline-content {
  flex: 1 1;
  text-align: left;
}

.timeline-item.alternate .timeline-content {
  text-align: right;
}

.timeline-image {
  max-width: 300px;
  height: auto;

}

.left-image {
  justify-content: flex-end; /* Position image to the right */
}

.right-image {
  justify-content: flex-start; /* Position image to the left */
}

.week-label {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #9CE2D3;
}

.description {
  font-size: 1rem;
  color: #555;
}

.item1 p {
  margin:0 auto;
}

.box1 {
  grid-column-start: 1;
  grid-column-end: 2;
  border-radius: 20px;
  height: auto;
  padding-block: 5%;
  padding-inline: 5%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1), 0 0 15px rgba(0,0,0,0.1); 
}

.box-title {
  font-size: 2em;
  font-weight: bold;
  padding-bottom: 5%
}

.box-description{
  font-size: 1em;
  padding-right: 30%;
}

.page1 {
  margin-top:5%;
  margin-left: 10%;
  margin-right: 10%;
}
/* .container {
  
} */

.shorthero{
    height: 100% !important;
}

.ht{
        position: absolute;
        top: 250px;
        transform: translateY(-50%); /* Centers it vertically */
        color: white;
        text-align: left;
        z-index: 2;
        max-width: 40%;
        margin-right: 60%;
        padding-left: 8%;
}

@media (max-width: 1300px){
    .joinContainer h2{
        font-size: 22px;
    }

}

@media (max-width: 700px){
    .h{
        font-size: 22px;
        margin-left: 20px
    }

    .shorthero{
        height: 50vh !important;
    }

}

.scroll-container {
    overflow-x: auto;  /* Enables horizontal scrolling */
    scrollbar-width: thin; /* Custom scrollbar styling */
    scrollbar-color: #ccc transparent;
    padding-bottom: 10px;
  }
  
  /* For Webkit browsers (Chrome, Safari) */
  .scroll-container::-webkit-scrollbar {
    height: 8px;
  }
  
  .scroll-container::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 5px;
  }
  
  .scroll-container::-webkit-scrollbar-track {
    background: transparent;
  }
  
.color-nav {
  position: relative;
  display: inline-flex;
  padding: 56px;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 48px;
  gap: 48px;
  margin-top: 56px;
  background: var(--3, #ffffff);
  outline: solid 1px rgb(219, 219, 219);
}

.footer .navbar-brand:hover,
.footer .nav-link:hover {
  color: #000000;
  transition: 0.3s ease;
  text-decoration: underline;
  -webkit-text-decoration-color: #9ce2d3;
          text-decoration-color: #9ce2d3;
  text-decoration-thickness: 3px;
}

.icon {
  border-radius: 19.286px;
  background: linear-gradient(
      0deg,
      rgba(156, 226, 211, 0.6) 0%,
      rgba(156, 226, 211, 0.6) 100%
    ),
    #fff;
  display: flex;
  width: 36px;
  height: 36px;
  padding: 10.286px;
  justify-content: center;
  align-items: center;
  grid-gap: 6.429px;
  gap: 6.429px;
}

.pages {
  width: 70%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
}

.footerNav {
  width: 100%;
  display: flex;
  padding: 0px 100px;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}

.copyright {
  color: var(--G1, #959598);
  font-family: Mulish;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  display: flex;
  width: 1728px;
  padding: 0px 20px;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 16px;
  gap: 16px;
}

.footer {
  overflow: hidden !important;
}

@media (max-width: 760px) {

  .fixed-bottom{
    background-color: #77AEA2 !important;
  }
  .copyright {
    padding: 0;
    text-align: left;
    color: white !important;
  }

  .navbar-text{
    color: white;
  }

  .footerNav {
    flex-direction: column;
    padding: 15px 0;
    width: auto;
    margin: 0;
    align-items: left;
    display: inline;
  }

  .pages {
    flex-direction: column;
    justify-content: left;
    margin-top: 15px;
    display: block;
    margin: 15 0 0 0;
    padding: 0;
    
  }

  .color-nav{
    grid-gap: 10px !important;
    gap: 10px !important;
  }

  .mr-auto {
    justify-content: left;
    margin: 0;
  }

  .navbar-nav {
    margin-top: 10px;
    justify-content: left;
    padding: 0;
  }

  .navbar .navbar-brand {
    display: flex;
    flex-direction: row;
    padding-top: 15px;
    justify-content: left;
    color: white;
    font-weight: bold;
  }
  .copyright {
    width: 100%;
    color: white;
  }

  

  .nav-link{
    padding-left: 0 !important;
    padding-right: 15px !important;
    width: 80px;
  }
  .footerwicc{
    display: none;
  }

  .color-nav {
    margin-top: 0px;
  }
}

