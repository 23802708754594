/* Base style for very small screens */
/* .members-grid {
  display: grid;
  grid-template-columns: 1fr; */
/* 1 column for the smallest screens */
/* gap: 10px; */
/* } */

/* Intermediate screens (e.g., large phones/small tablets) */
@media (max-width: 768px) {
  .members-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* 2 columns for intermediate screens */
  }
}

/* Medium screens (e.g., tablets) */
@media (min-width: 768px) {
  .members-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* 3 columns for medium screens */
  }
}

/* Large screens (e.g., desktops) */
@media (min-width: 1024px) {
  .members-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    /* 4 columns for large screens */
  }
}