body {
  margin: 0; /* Avoids any default body margins */
  overflow-x: hidden; /* Prevents horizontal scrolling */
}

.container {
  max-width: 100%; /* Ensures content stays within the screen */
  overflow-x: hidden; /* Forces no overflow horizontally */
}

.home-container {
  padding-bottom: 20px;
  max-width: 100%;
}
/* 
.responsive-text {
  display: none;
} */

.hero-content{
  left: 35% !important;
}

.downward-arrow {
  position: absolute;
  bottom: 40px;  /* Adjust position from the bottom */
  left: 50%;
  transform: translateX(-50%);
  width: 50px;  /* Adjust size */
  height: auto;
  opacity: 0.8;
  transition: opacity 0.3s ease;
  animation: bounce 1.5s infinite;
  cursor: pointer;
}

/* Smooth hover effect */
.downward-arrow:hover {
  opacity: 1;
}

/* Bouncing animation */
@keyframes bounce {
  0%, 100% {
    transform: translateX(-50%) translateY(0);
  }
  50% {
    transform: translateX(-50%) translateY(10px);
  }
}

/* Show only for screens larger than 414px */
@media screen and (min-width: 415px) {
  .responsive-text {
    display: block;
  }
}

.hero-container {
  position: relative;
}

.hero-content {
  position: absolute;
  top: 50%;
  z-index: 1;
  padding-left: 4rem;
  text-align: left;
  color: #fff;
}

.hero-content h2 {
  font-size: 3rem;
  text-align: left;
  color: #fff;
  margin-left: 2rem;
  max-width: 800px;
  font-weight: bold;
}

.hero-content p {
  font-size: 1rem;
  text-align: left;
  max-width: 600px;
  margin: 0 auto;
  margin-left: 0;
}

.mission-image {
  width: 100%;
}

.semout-container {
  display: flex;
  flex-direction: row;
  align-content: left;
  justify-content: flex-start;
}

.semout-content {
  display: flex;
  flex-direction: column;
  align-content: left;
  justify-content: center;
  margin-top: 5em;
  margin-left: 10em;
  margin-bottom: 2em;
}

.semout-title {
  text-align: left;
  font-weight: bold;
  margin-bottom: 1em;
}

.semout-description {
  text-align: left;
  max-width: 800px;
}

/* work starts here */

.what-we-do-container {
  padding: 2em;
}

.what-we-do-header {
  text-align: left;
}

.what-we-do-title {
  font-weight: bold;
  margin-left: 4em;
}

.what-we-do-content {
  padding-inline: 8em;
  display: flex;
  flex-direction: row;
  gap: 8em;
  display: flex;
  justify-content: space-between;
  margin-inline: auto;
}

.what-we-do-column {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  margin-inline: auto;
}

.what-we-do-image {
  width: 100%;
  height: auto;
}

.what-we-do-description,
.what-we-do-text {
  margin-inline: 2em;
}

.btn {
  border-radius: 5em;
  color: black;
  background-color: #9ce2d3;
  border-color: #9ce2d3;
}

.what-we-do-button {
  border-radius: 5em;
  color: black;
  background-color: #9ce2d3;
  border-color: #9ce2d3;
  margin-bottom: 2em;
  margin-left: 2em;
  width: 90%;
}

.btn:hover {
  background-color: black; /* Hover background color */
  color: white; /* Hover text color */
}

.call-to-action-container {
  display: flex;
  justify-content: center;
  text-align: center;
  padding-left: 4rem;
  padding-right: 4rem;
}

.get-involved {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.get-involved-header {
  text-align: left;
  font-weight: bold;
  margin-left: 5rem;
}

.get-involved-grid {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.get-involved-columno {
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: #9ce2d3;
  min-height: 150px;
  border-radius: 25px;
  padding: 1.5rem;
  margin: 10px;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.15);
  justify-content: flex-start; /* Prevents stretching */
  min-height: auto; /* Ensures it only grows as needed */
}

.get-involved-columne {
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: white;
  min-height: 150px;
  border-radius: 25px;
  padding: 1.5rem;
  margin: 10px;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.15);

  justify-content: flex-start; /* Prevents stretching */
  min-height: auto; /* Ensures it only grows as needed */
}

.get-involved-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: flex-start; /* Ensures everything inside is left-aligned */
  justify-content: flex-end;
  min-height: auto;

}



.button-name,
.button-description {
  display: flex;
  justify-content: flex-start;
  margin-top: auto; /* Pushes text to the bottom */
  align-self: flex-end;
}



.button-name {
  display: flex;
  justify-content: flex-start;
  font-weight: 800;
  text-align: left;
  margin-top: auto;
}

.carousel-wrapper {
  position: relative;
  width: 100%;
  max-width: 100%;
  height: 200px;
  margin: 0 auto;
}

.carousel img {
  width: 100%;
  height: 200px;
  object-fit: fill;
}

.carousel .control-arrow {
  padding: 0;
}

.carousel .control-dots {
  position: absolute;
  bottom: -30px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  color: black;
}

.carousel .control-dots .dot {
  background-color: black;
  color: black;
  opacity: 0.8;
}

.stats {
  display: flex;
  justify-content: center;
  align-items: center;
}

.stat-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 20px;
  text-align: center;
  margin-bottom: 1em;
}

.stat-item strong {
  font-weight: bold;
  color: #9ce2d3;
  font-size: 2em;
}

.stat-item span {
  font-weight: bold;
}

.alumni-section {
  background-color: #fafafa;
  width: 100%;
  padding: 3em 0;
}

.alumni-header {
  text-align: left;
  font-weight: bold;
  margin-left: 5em;
  margin-bottom: 1.5rem;
}

.testimony-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 8rem;
  padding-right: 8rem;
}

.testimony {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #ffffff;
  border-radius: 2rem;
  padding: 2rem;
  margin: 0 20px;
}

.alumni-name {
  margin-bottom: 1rem;
  font-size: 1.2rem;
  font-weight: bold;
}

@media (max-width: 960px) {
  .hero-container .hero-content h2 {
    font-size: 1.8rem;
  }
}

@media (max-width: 760px) {
  .hero-container {
    position: relative;
    height: 100vh;
    overflow: hidden;
    margin-top: -20px;
  }

  .mission-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .hero-content {
    position: absolute;
    top: 40% !important;
    left: 35%;
    transform: translate(-50%, -50%);
    z-index: 1;
    text-align: left;
    color: #fff;
    padding: 20px;
    margin-inline: 1rem;
    margin-left: 0;
    top:40%;
  }

  .hero-container .hero-content h2 {
    font-size: 1.5rem;
    padding-top: 100px;
  }

  .hero-content p {
    font-size: 0.9rem;
  }

  .semout-title {
    margin-bottom: 0.5em;
    margin-left: -1rem;
  }

  .semout-content {
    margin-top: 2em;
    margin-inline: 4em;
    margin-bottom: 0rem;
  }

  .what-we-do-content {
    transform: translateY(-10px);
    flex-direction: column;
    align-items: center;
    padding-inline: 0em;
    padding-bottom: 1em;
    gap: 0em;
  }

  .what-we-do-button {
    border-radius: 5em;
    color: black;
    background-color: #9ce2d3;
    border-color: #9ce2d3;
    margin-bottom: 2em;
    margin-left: 1em;
    width: 90%;
  }

  .what-we-do-column {
    margin-left: 0;
    width: 100%;
  }

  .what-we-do-title {
    margin-left: 1rem;
  }

  .what-we-do-image {
    max-width: 100%;
  }

  .alumni-section {
    padding: 2em;
  }

  .alumni-header {
    margin-left: 0.5rem;
  }

  .testimony-container {
    flex-direction: column;
    padding-left: 0;
    padding-right: 0;
  }

  .testimony {
    margin: 20px;
  }

  .get-involved {
    padding-top: 2em;
    padding-bottom: 2em;
  }

  .get-involved-header {
    margin-left: 1.5em;
    margin-bottom: 0;
  }

  .call-to-action-container {
    display: flex;
    justify-content: center;
    width: 100%;
    float: left;
    flex-wrap: wrap;
    flex-direction: row;
    padding: 1.5em 2em;
  }

  .get-involved-grid {
    display: flex;
    width: auto;
    flex-wrap: wrap;
  }

  .get-involved-columno {
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: #9ce2d3;
    min-height: 150px;
    border-radius: 25px;
    padding: 1.2rem;
    margin: 0.5em;
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.15);
    max-width: 45%;
  }

  .get-involved-columne {
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: white;
    min-height: 150px;
    border-radius: 25px;
    padding: 1.2rem;
    margin: 0.5em;
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.15);
    max-width: 45%;
  }

  .arrow-img {
    margin-bottom: 1em;
  }

  .button-description {
    text-align: left;
    font-size: 12px;
  }

  .button-name {
    font-size: 18px;
    text-align: left;
  }

  .sponsor-container {
    padding: 2em;
  }

  .home-container {
    padding-bottom: 0px;
  }
}
