.navbar {
  position: absolute;
  top: 0;
  padding-left: 70px;
  padding-right: 70px;
  background-color: transparent !important;
  z-index: 50;
  width: 100%;
}

.toggle-button:hover {
  color: #9ce2d3 !important;
  transition: 0.5s ease;
}

.navbar .navbar-nav .nav-link:hover {
  color: #9ce2d3 !important;
  transition: 1s ease;
  text-decoration: underline;
  text-decoration-color: #fff;
  text-decoration-thickness: 3px;
  font-size: 16.5px;
  font-weight: bolder;
}

.navbar .navbar-brand {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 20px;
}

.links {
  display: flex;
  flex-direction: row;
  width: 70%;
  justify-content: space-between;
}

.navbar .navbar-brand .brand-text {
  display: flex;
  flex-direction: row;
  margin-left: 10px;
  text-align: left;
  font-size: 14px;
}

.navbar .join-us-button .btn {
  background-color: transparent;
  color: white;
  border: 1px solid white;
}

.navbar .join-us-button .btn:hover {
  cursor: pointer;
  background-color: #9ce2d3 !important;
  font-weight: bolder;
  color: black;
}

.navbar-logo {
  padding-bottom: 1rem;
}

@media (max-width: 960px) {
  body {
    margin-top: 20%;
  }
  .navbar {
    position: relative;
    top: 0;
    z-index: 5;
    width: 100%;
    padding-inline: 40px;
    color: black;
    padding-left: 0px;
    padding-right: 0px;
  }

  .mobile-navbar {
    background-color: white !important;
    padding-inline: 30px;
  }

  .mobile-links {
    background-color: white !important;
    width: 100%;
    top: 70px;
    padding-bottom: 1.5rem;
  }

  .mobile-links .nav-link {
    color: black !important;
  }

  .mobile-links .nav-link:hover {
    color: #9ce2d3 !important;
  }

  .navbar .join-us-button {
    padding-top: 0.8rem;
  }

  .navbar .join-us-button .btn {
    background-color: black;
    color: white;
  }
  .toggle-button{
    color: black
  }
}
