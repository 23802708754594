.hero {
    flex-shrink: 0;
    color: white;
  }
  
  .hero-text {
    position: absolute;
    top: 10%;
    margin-top: 5%;
    /* left: 0; */
    /* transform: translateY(-50%); */
    color: white;
    text-align: left;
    z-index: 2;
    /* max-width: 40%; */
    margin-right: 60%;
    padding-left: 8%;
  }
  
  .hero-image {
    filter: brightness(40%);
    object-fit: cover;
    overflow: hidden;
    width: 100%;
    max-height: 700px;
  }
  
  .hero-text a {
    color: white;
    text-decoration: underline;
  }
  
  .hero-text a:hover {
    box-shadow: 0px 1px 1px 1px rgb(68, 68, 68);
  }