.sponsor-container {
  display: flex;
  padding-top: 56px;
  padding-left: 5%;
  padding-right: 5%;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}

.sponsor-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.sponsor-title {
  text-align: left;
  padding-left: 0.5em;
  font-weight: bold;
  color: #000;
  font-family: "Mulish", sans-serif;
  font-size: 36px;
  font-style: normal;
  line-height: 150%;
}

.sponsor-carousel-container {
  object-fit: cover;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 20px;
}

@media (max-width: 760px) {
  .sponsor-container {
    padding: 2em;
    padding-inline: 3rem;
  }

  .sponsor-title {
    font-size: 30px;
  }
  .sponsor-carousel-container {
    padding-top: 0;
  }
}
