h2 {
color: black
}

.containerPage{
  /* margin: 5%; */
  /* margin-top: 25px; */
  margin: 5%;
  margin-left: 12%;
  margin-right: 12% 
}

.container {
  display: flex;
  flex-wrap: wrap; /* This will allow items to wrap to the next line if they don't fit */
  justify-content: center; /* Adjust as per your design */
}

.rowC{
  display:flex; 
  flex-direction:row;
  columns: 2 auto;

}

.fakeCard {
  margin-bottom: 200px;
}

/* .col {
  float: left
} */

.col {
  flex: 1;
  margin: 10px; /* Example margin, adjust as needed */
  box-sizing: border-box; /* Ensures padding and border are included in the width */
}

.sponsorStyle {
  margin-top: 0px;
  margin-bottom: 60px;
}

.faculty{
  margin-left:5%;
  margin-right:5%

}

.extra BoardCard {
  color: white
}