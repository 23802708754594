/* .custom-progress-bar .progress-bar {
  background-color: #9CE2D3;
  height: 10;
} */
body {
  overflow-x: hidden;
}

.hero {
  flex-shrink: 0;
  flex-direction:column;
  color: white;
  min-height: 300px;
  /*pushes everything down (doesnt help the text) */
}

.hero-text {
  position: absolute;
  /* top: 15%; */
  margin-top: 30%;
  /* left: 0; */
  /* transform: translateY(-50%); */
  color: white;
  text-align: left;
  z-index: 2;
  max-width: 40%;
  margin-right: 60%;
  padding-left: 8%;
}

.hero-image {
  filter: brightness(40%);
  object-fit: cover;
  overflow: hidden;
  width: 100%;
  max-height: 700px;
}

.hero-text a {
  color: white;
  text-decoration: underline;
}


.downward-arrow {
  position: absolute;
  bottom: 40px;  /* Adjust position from the bottom */
  left: 50%;
  transform: translateX(-50%);
  width: 50px;  /* Adjust size */
  height: auto;
  opacity: 0.8;
  transition: opacity 0.3s ease;
  animation: bounce 1.5s infinite;
  cursor: pointer;
}

/* Smooth hover effect */
.downward-arrow:hover {
  opacity: 1;
}

/* Bouncing animation */
@keyframes bounce {
  0%, 100% {
    transform: translateX(-50%) translateY(0);
  }
  50% {
    transform: translateX(-50%) translateY(10px);
  }
}

@media screen and (min-width: 731px) {
  .responsive-text {
    display: block;
  }
}

.hero-text a:hover {
  box-shadow: 0px 1px 1px 1px rgb(68, 68, 68);
}

.what-we-do {
  font-size: 30px;
  font-weight: bold;
  text-align: left;
  margin-top: 5%;
  margin-bottom: 0;
  margin-left: 5%;
  max-width: 1000px;
  width: 100%;
}

.card {
  position: relative;
  transition: transform 0.3s, box-shadow 0.3s;
  border: none;
  box-shadow: none;
}

.card:hover .image {
  filter: brightness(50%);
  transition: filter 0.3s, transform 0.3s;
}

.card:hover .card-title {
  color: #9ce2d3;
}

.image {
  display: block;
  width: 100%;
  aspect-ratio: 1 / 0.85;
  object-fit: cover;
  border-radius: 10px;
  transition: filter 0.3s, transform 0.3s;
}

.card-title {
  padding-top: 10px;
  font-size: 18px;
  font-weight: bold;
  text-decoration: underline;
  color: black;
  transition: color 0.3s ease;
}

.card-title:hover {
  color: #9ce2d3;
  text-decoration: underline;
}

.upcoming-title {
  font-size: 30px;
  font-weight: bold;
  text-align: left;
  margin: 5%;
  max-width: 1000px;
  width: 100%;
}

.calendar-wrapper {
  margin: 0 auto;
  width: 100%;
}

.calendar-wrapper iframe {
  width: 100%;
  border: solid 1px #777;
  height: 600px;
}

.calendar-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 5% 5% 5%;
  max-width: 1000px;
}

.button-display {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  margin-right: 30px;
}

.event-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.events-grid {
  margin: 4% 5%;
  overflow-x: hidden;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 30px;
  max-width: 1000px;
}

.item1 {
  grid-column-start: 1;
  grid-column-end: 2;
  background: var(--Cyan-2, #dcf3ed);
  border-radius: 20px;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
}

.item1 h1 {
  color: #000;

  /* Sub Title - 1 */
  font-family: Mulish;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  /* 60px */
  margin-left: 20%;
  text-align: left;
}

.item1 p {
  color: #000;
  /* Header-Paragraph */
  font-family: Mulish;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin-left: 20%;
  text-align: left;
}

@media (max-width: 1300px){
  .hero-text{
    margin-right: 0 !important;
    max-width: 80% !important;
  }
}

@media (max-width: 1000px) {
  .events-grid {
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    margin: 4% 5%;
  }

  .item1 {
    grid-column-start: auto;
    grid-column-end: auto;
  }

  .item1 h1,
  .item1 p {
    margin: 0 auto;
  }

  .hero-text {
    position: absolute;
    /* top: 15%; */
    margin-top: 5% 4%;
    /* left: 0; */
    /* transform: translateY(-50%); */
    color: white;
    text-align: left;
    z-index: 2;
    /* max-width: 40%; */
    margin-right: 10%;
    padding-left: 8%;
  }
}
