.floating-menu {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #f8f9fa;
  border: 1px solid #ddd;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1000;
}

.floating-menu.open {
  border-radius: 10px;
  width: 200px;
  height: auto;
  padding: 20px;
  flex-direction: column;
  align-items: left;
}

.menu-icon {
  font-size: 30px;
  transition: all 0.3s ease;
}

.menu-icon.top-left {
  position: absolute;
  top: 10px;
  left: 10px;
}

.floating-menu .nav {
  display: block;
  margin-top: 20px;
}

/* Mobile Devices */
@media (max-width: 600px) {}

/* Tablets */
@media (min-width: 601px) and (max-width: 1140px) {}

/* Desktops and larger screens */
@media (min-width: 1140px) {
  .floating-menu {
    display: None;
  }
}