h2 {
  color: black;
}

.hero-container {
  position: relative;
}

.hero-content {
  position: absolute;
  top: 50%;
  left: 30%;
  transform: translate(-50%, -50%);
  z-index: 1;
  text-align: left;
  color: #fff;
}
/* 
.responsive-text {
  display: none;
} */
.responsive-title {
  font-size: 3rem;
  position: static;
  text-align: left;
}

.downward-arrow {
  position: absolute;
  bottom: 40px;  /* Adjust position from the bottom */
  left: 50%;
  transform: translateX(-50%);
  width: 50px;  /* Adjust size */
  height: auto;
  opacity: 0.8;
  transition: opacity 0.3s ease;
  animation: bounce 1.5s infinite;
  cursor: pointer;
}

/* Smooth hover effect */
.downward-arrow:hover {
  opacity: 1;
}

/* Bouncing animation */
@keyframes bounce {
  0%, 100% {
    transform: translateX(-50%) translateY(0);
  }
  50% {
    transform: translateX(-50%) translateY(10px);
  }
}


/* Show only for screens larger than 414px */
@media screen and (min-width: 761px) {
  .responsive-text {
    display: block;
  }
}

.hero-content h2 {
  font-size: 3rem;
  text-align: left;
  color: #fff;
  margin-left: 0;
  max-width: 800px;
  font-weight: bold;
}

.hero-content p {
  font-size: 1rem;
  text-align: left;
  max-width: 600px;
  margin: 0 auto;
  margin-left: 0;
}

.containerPage {
  margin: 5%;
  margin-left: 12%;
  margin-right: 12%;
}

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.rowC {
  display: flex;
  flex-direction: row;
}

.fakeCard {
  margin-bottom: 200px;
}

.col {
  flex: 1;
  margin: 10px;
  box-sizing: border-box;
}

.sponsorStyle {
  margin-top: 0px;
  margin-bottom: 60px;
}

.faculty {
  margin-left: 5%;
  margin-right: 5%;
}

.extra BoardCard {
  color: white;
}

/* Mobile Devices */
@media (max-width: 600px) {
  .containerPage {
    margin-left: 5%;
    margin-right: 5%;
  }

  .rowC {
    flex-direction: row;
    flex-wrap: wrap;
    /* Allow wrapping for multiple rows */
  }

  .col {
    flex: 1 0 50%;
    /* Each column takes up 50% width */
    box-sizing: border-box;
    /* Ensure padding and border are included in the width */
    padding: 5px;
    /* Add some spacing between columns */
  }

  .right-sidebar {
    display: none;
    /* Hide the sidebar on smaller screens */
  }

  p {
    font-size: smaller;
  }

  .wwa {
    display: none;
  }
  .wwa2{
    display: flex !important;
  }
}

/* Tablets */
@media (min-width: 601px) and (max-width: 1140px) {
  .containerPage {
    margin-left: 8%;
    margin-right: 8%;
  }

  .col {
    flex: 1 50%;
    /* Columns take up half the width */
  }

  .right-sidebar {
    display: none;
    /* hide the sidebar on mid-sized screens */
  }
}

/* Desktops and larger screens */
@media (min-width: 1140px) {
  .containerPage {
    margin-left: 12%;
    margin-right: 12%;
  }

  .right-sidebar {
    display: flex;
    /* ensure it's visible on large screens */
    float: right;
    height: 100%;
    flex-direction: column;
    align-items: flex-end;
    position: sticky;
    top: 0;
  }

  .rowC {
    flex-direction: row;
    columns: 2 auto;
  }

  .col {
    flex: 1;
    /* Default flexibility */
  }
  .hero-container{
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }
  .hero-container img {
    /* width: 100%;
    height: 100%; */
    object-fit: cover; /* Ensures the image covers the entire area without distortion */
  }
}
