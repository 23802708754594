/* .home-container {
  margin: 5%;
}

.hero-container, .mission-container, .call-to-action-container {
  margin: 5% 0;
}

.hero-container {
  overflow: hidden;
}

#home-illustration {
  width: 40%;
  float: right;
}

.call-to-action-container {
  margin-bottom: 15%;
  text-align: center;
} */

/* react-bootstrap button style overriding */
/* .btn {
  border-radius: 5em;
}

.btn-primary {
  color: black;
  background-color: #9CE2D3;
  border-color: #9CE2D3;
}

.headerName {
  font-size: 50px;
} */

body {
  margin: 0;
  overflow-x: hidden;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.container {
  width: 100vw;
  max-width: 100%;
  overflow-x: hidden;
}

.column {
  float: left;
  padding: 30px;
}

.left {
  padding-left: 100px;
  width: 40%;
}

.right {
  width: 60%;
  text-align: center;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

.row {
  margin-left: auto;
  margin-right: auto;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}
